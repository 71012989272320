import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import schoolList from "../schoolsList.json";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import '../App.css';
import emailjs from "@emailjs/browser";
import validator from 'validator';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { Galleria } from 'primereact/galleria';
import { Rating } from 'primereact/rating';
import { Fieldset } from 'primereact/fieldset';
import { Skeleton } from 'primereact/skeleton';
import { Card } from 'primereact/card';
import ScrollButton from '../components/ScrollButton.js';
import { ProgressSpinner } from 'primereact/progressspinner';
import ReCAPTCHA from "react-google-recaptcha";





export default function SchoolDetailsPage() {
  let { code } = useParams();

  // const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const API_KEY = "AIzaSyBt3uyu61bVwcU-igV9LgRi-WKA1ONlUrk";

  const [filteredSchool, setFilteredSchool] = useState(null);

  const [googleSchoolDetails, setGoogleSchoolDetails] = useState(null);

  const [markerRef, marker] = useAdvancedMarkerRef();

  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const [visible, setVisible] = useState(false);

  // const [photoURLs, setPhotoURLs] = useState("https://retchhh.wordpress.com/wp-content/uploads/2015/03/loading1.gif")
  const [photoURLs, setPhotoURLs] = useState(null)



  const form = useRef();
  const toast = useRef(null);

  const recaptchaRef = useRef();


  const getSchoolDetails = (code) => {
    const a = schoolList.filter((school) => {
      return school.Code === code;
    });

    return a;
  };

  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    []
  );

  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  const sendEmail = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();


    form.current.user_name.value === "" ? toast.current.show({ severity: 'contrast', summary: 'Missing...', detail: 'please enter your name.', life: 3000 }) :
      validator.isEmail(form.current.user_email.value) === false ? toast.current.show({ severity: 'contrast', summary: 'Invalid...', detail: 'please re-enter your email.', life: 3000 }) :
        (form.current.user_name.value !== "" && form.current.user_email.value !== "" ?
          emailjs
            .sendForm("service_3mo0rtp", "template_3i1fdz7", form.current, {
              publicKey: "YZMwlC9mDcIizVZ4N",
              "g-recaptcha-response": recaptchaValue
            })
            .then(
              () => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Sent email already! Please check your mail.  If you do not see the email in your inbox, please check your junk mail folder.' });
              },
              (error) => {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Server busy, please try again.' });
              }
            )
          :
          <></>
        )


  }

  async function getDetailsFromGoogle(filteredSchool) {
    try {
      if (filteredSchool && filteredSchool.Phone) {
        const res = await fetch("https://places.googleapis.com/v1/places:searchText", {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": API_KEY,
            "X-Goog-FieldMask": "*"
          },
          body: JSON.stringify({
            "textQuery": `${filteredSchool.SchoolName}`

            // "textQuery": `${filteredSchool.Street}, ${filteredSchool.Suburb} ${filteredSchool.State} ${filteredSchool.PostCode}`
            // "textQuery": `+61 ${filteredSchool.Phone}`

          })
        })
        const data = await res.json();
        return data
      }
    }
    catch (e) {
      console.error("FAILED", e)
    }
  }

  function getPhotoFromGoogle(googleSchoolDetails) {
    let result = []
    if (googleSchoolDetails && googleSchoolDetails.places) {
      if (googleSchoolDetails.places[0].photos) {
        for (const place of googleSchoolDetails.places[0].photos) {
          let photoURL = `https://places.googleapis.com/v1/${place.name}/media?maxHeightPx=800&maxWidthPx=600&key=${API_KEY}`;
          result.push(photoURL)
        }
      } else {
        result.push("https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg")
      }
    }
    else {
      result.push("https://retchhh.wordpress.com/wp-content/uploads/2015/03/loading1.gif")
    }
    return result

  }




  useEffect(() => {
    const result = getSchoolDetails(code);
    setFilteredSchool(result[0]);
  }, [code]);

  useEffect(() => {
    const googleDetails = async () => {
      try {
        if (filteredSchool) {
          const result = await getDetailsFromGoogle(filteredSchool);
          if (result.error) {
            console.log("FAILED", result.error)
          } else {
            setGoogleSchoolDetails(result);
          }
        }
      } catch (err) {
        console.error('Error getting school details from Google:', err);
      }
    };
    googleDetails();

  }, [filteredSchool]);


  useEffect(() => {
    const result1 = getPhotoFromGoogle(googleSchoolDetails);
    setPhotoURLs(result1);

  }, [googleSchoolDetails]);

  const itemTemplate = (photoURLs) => {
    return <img src={photoURLs} alt="valid" style={{ maxHeight: '440px', maxWidth: '100%' }} />
  }

  return (
    <>
      <div className="container ">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Link to={"/"} >
              <i className="pi pi-home" style={{ color: 'var(--primary-color)', fontSize: '2rem' }}></i>
            </Link>
          </div>
          <div className="topic">
            School Details <i className="pi pi-graduation-cap" style={{ fontSize: '2rem' }}> </i></div>

        </div>


        <div className="d-flex justify-content-around align-items-center m-3 direction">

          {/* Details */}
          <div className="col-lg-5 col-12 m-3">
            {filteredSchool ?

              <Card title={filteredSchool.SchoolName} footer={
                <>
                  <Button label="Share details" icon="pi pi-envelope" onClick={() => setVisible(true)} className="rounded" />
                  <Dialog header="send details by email" visible={visible} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>
                    <form ref={form} onSubmit={sendEmail}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LcjYP8pAAAAAE4Xea9TXQKXjNgM0nHS8b4vTuLF"
                      // onChange={onChange}
                      />
                      <label htmlFor="user_name">Your Name</label>
                      <InputText id="user_name" name="user_name" className="m-3" />
                      <label htmlFor="user_email">Your Email</label>
                      <InputText id="user_email" name="user_email" className="m-3" />
                      {Object.entries(filteredSchool).map(([key, value]) => (
                        <input key={key} style={{ display: "none" }} type="text" name={key} defaultValue={value} />

                      ))}

                      {googleSchoolDetails && googleSchoolDetails.places ?
                        (googleSchoolDetails.places[0].websiteUri ?
                          <input key={"websiteUri"} style={{ display: "none" }} type="text" name={"websiteUri"} defaultValue={googleSchoolDetails.places[0].websiteUri} />
                          : <></>
                        )
                        : <> </>
                      }



                      <Button type="submit" className="rounded m-3">Send</Button>
                      {/* <input type="submit" value="Send" /> */}
                      <Toast ref={toast} />

                    </form>
                  </Dialog>
                </>
              } className="md:w-25rem">
                <p className="m-0">
                  {/* <div>Name: {filteredSchool.SchoolName}</div> */}
                  <div>Address: {filteredSchool.Street} {filteredSchool.Suburb} {filteredSchool.Postcode} {filteredSchool.State}</div>
                  <div>Classification: {filteredSchool.BroadClassification}</div>
                  <div>Education Region: {filteredSchool.EducationRegion}</div>
                  {filteredSchool.LowYear ?
                    <div>Lowest Year: {filteredSchool.LowYear}</div>
                    : <div>Lowest Year: N/A</div>
                  }
                  {filteredSchool.HighYear ?
                    <div>Highest Year: {filteredSchool.HighYear}</div>
                    : <div>Highest Year: N/A</div>
                  }
                  {filteredSchool.TotalStudents ?
                    <div>Total of student: {filteredSchool.TotalStudents}</div>
                    : <div>Total of student: N/A</div>
                  }
                  <div>Phone: {filteredSchool.Phone}</div>

                  {googleSchoolDetails && googleSchoolDetails.places ?
                    (googleSchoolDetails.places[0].websiteUri ?
                      <span>Website:
                        <Link to={googleSchoolDetails.places[0].websiteUri} target="_blank" style={{ color: '#4b5563', "textDecoration": "none" }} >
                          <span style={{ color: '#4b5563' }}>  {googleSchoolDetails.places[0].websiteUri}</span>
                        </Link>
                      </span>

                      : <></>
                    )
                    : <> </>
                  }
                </p>
              </Card>
              :
              <ProgressSpinner />

            }
          </div>



          {/* Photos */}
          <div className="">
            {photoURLs ?

              <Galleria value={photoURLs} numVisible={5} style={{ maxWidth: '640px', backgroundColor: 'lightgrey' }}
                item={itemTemplate} showItemNavigators
                showThumbnails={false} autoPlay transitionInterval={2000} className="rounded" />
              :
              <div>
                Loading
              </div>
            }
          </div>





        </div>

        {/* Map */}
        <div className="d-flex align-items-center justify-content-center m-5 border border-light-subtle ">

          {filteredSchool && filteredSchool.Latitude && filteredSchool.Longitude ?
            <>
              <APIProvider apiKey={API_KEY}>
                <Map
                  style={{ width: "100vw", height: "70vh" }}
                  defaultCenter={{ lat: Number(filteredSchool.Latitude), lng: Number(filteredSchool.Longitude) }}
                  defaultZoom={15}
                  gestureHandling={"greedy"}
                  disableDefaultUI={true}
                  mapId="168be9b994d92a24"
                >
                  <AdvancedMarker
                    ref={markerRef}
                    position={{ lat: Number(filteredSchool.Latitude), lng: Number(filteredSchool.Longitude) }}
                    onClick={handleMarkerClick}
                  />
                  {infoWindowShown && (
                    <InfoWindow anchor={marker} onClose={handleClose}>
                      {/* <h2>School information</h2> */}
                      <div>{filteredSchool.SchoolName}</div>
                    </InfoWindow>
                  )}


                </Map>

              </APIProvider>

            </>

            :

            <Skeleton height="4rem" className="mb-2" borderRadius="16px"></Skeleton>


          }



        </div>





        {/* Google Reviews */}
        <div className="d-flex flex-column align-items-center m-3">
          <div className="topic">Google reviews <i className="pi pi-comment" style={{ fontSize: '2rem' }}> </i></div >

          {googleSchoolDetails && googleSchoolDetails.places ?
            (googleSchoolDetails.places[0].rating ?


              <div className="d-flex">
                <span className="m-2" style={{ color: '#4b5563' }}>
                  Rating: {googleSchoolDetails.places[0].rating}
                </span>
                <Rating value={googleSchoolDetails.places[0].rating} readOnly cancel={false} stars={5} />
              </div> : <></>

            ) : <></>
          }



          {googleSchoolDetails && googleSchoolDetails.places ?
            (
              googleSchoolDetails.places[0].reviews && googleSchoolDetails.places ?

                googleSchoolDetails.places[0].reviews.map((g) => (

                  <div className="row col-lg-7 col-12" >

                    <div className="card">
                      <Fieldset legend={(g.authorAttribution ? g.authorAttribution.displayName : "Anonymous")} >
                        <p className="m-0">
                          <div>
                            <Rating value={g.rating} readOnly cancel={false} stars={5} />
                          </div>
                          {g.text ? <div>{g.text.text}</div> : <></>}
                          <div>{g.relativePublishTimeDescription}</div>
                        </p>
                      </Fieldset>
                    </div>

                  </div>

                ))
                :
                <div>No reviews</div>
            )
            :
            <>

              <Skeleton height="4rem" className="mb-2" borderRadius="16px"></Skeleton>
              <Skeleton height="4rem" className="mb-2" borderRadius="16px"></Skeleton>
            </>

          }
        </div>
        <ScrollButton />



        {/* <Marker position={{ lat:+filteredSchool.Latitude , lng: +filteredSchool.Longitude }} /> */}

        {/* fully customized marker
  <AdvancedMarker position={{lat: 29.5, lng: -81.2}}>
    <img src={markerImage} width={32} height={32} />
  </AdvancedMarker> */}
      </div >
    </>
  );
}
