import { Route, Routes } from 'react-router'
import SchoolDetailsPage from './pages/schoolDetailsPage';
import HomePage  from './pages/homePage';
import NotMatchPage from './pages/NotMatchPage';

export const routes = {
    home:'/',
    schoolDetails: '/school-details/:code',

}

export default function AppRoutes() {
    return (
        <Routes>
            <Route path={routes.home} element={< HomePage />} />
            <Route path={routes.schoolDetails} element={<SchoolDetailsPage />} />
            <Route path='*' element={<NotMatchPage />} />

        </Routes>
    )
}