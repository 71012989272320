import { useEffect, useState, useRef } from "react";
import schoolList from "../schoolsList.json";
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Sidebar } from 'primereact/sidebar';
import ScrollButton from '../components/ScrollButton.js';
import haversine from 'haversine'
import { ProgressSpinner } from 'primereact/progressspinner';



export default function HomePage() {
  // const [filteredSchool, setFilteredSchool] = useState(schoolList);

  const [filteredSchool, setFilteredSchool] = useState(null);
  const [searchSchoolName, setSearchSchoolName] = useState("");
  const [searchPostcode, setSearchPostcode] = useState("");
  const [searchClassification, setSearchClassification] = useState("");
  const [dropdownlist, setDropdownlist] = useState([]);
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [progressSpinner, setProgressSpinner] = useState(false);

  const orgSchoolList = useRef(schoolList);

  const handleInputSchoolName = (e) => {
    const searchSchoolName = e.target.value;
    setSearchSchoolName(searchSchoolName);
  };

  const handleInputPostcode = (e) => {
    const searchPostcode = e.target.value;
    setSearchPostcode(searchPostcode);
  };

  const handleClassification = (e) => {
    const searchClassification = e.target.value;
    setSearchClassification(searchClassification);
  };

  const classificationDropdown = () => {
    return setDropdownlist([
      ...new Set(schoolList.map((item) => item.BroadClassification)),
    ]);
  };

  const clearFilter = () => {
    setSearchPostcode("");
    setSearchSchoolName("");
    setSearchClassification("");
    setDropdownlist([]);
    classificationDropdown()

  };

  // const getUserLocation = () => {
  //   // if geolocation is supported by the users browser
  //   if (navigator.geolocation) {
  //     // get the current users location
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         // save the geolocation coordinates in two variables
  //         const { latitude, longitude } = position.coords;
  //         // update the value of userlocation variable
  //         // setUserLocation([latitude, longitude]);
  //         setUserLocation({ latitude: latitude, longitude: longitude });

  //       },
  //       // if there was an error getting the users location
  //       (error) => {
  //         console.error('Error getting user location:', error);
  //       }
  //     );
  //   }
  //   // if geolocation is not supported by the users browser
  //   else {
  //     console.error('Geolocation is not supported by this browser.');
  //   }
  // };


  const getUserLocation = async () => {

    try {

      if (navigator.geolocation) {

        const position = await new Promise((resolve, reject) => {

          navigator.geolocation.getCurrentPosition(
            position => {
              resolve(position);
            },
            err => reject(err)
          );

        });

        const { latitude, longitude } = position.coords;

        return { latitude, longitude };

      } else {
        console.error('Geolocation not supported');
      }

    } catch (err) {

      console.error(err);
      return null;

    }

  };

  const sortSchoolList = (filteredSchool) => {
    return filteredSchool.sort(function (a, b) {
      return a.distance - b.distance;
    });
  }



  const calDistance = (orgSchoolList, userLocation) => {

    if (orgSchoolList) {
      // console.log(filteredSchool)
      if (userLocation) {
        let temp = orgSchoolList.map((school) => {
          // console.log(userLocation)
          // console.log(school.Latitude)
          const distance = haversine(userLocation, {
            latitude: school.Latitude,
            longitude: school.Longitude
          });
          // console.log(distance)
          return {
            ...school,
            distance
          };
        })
        return sortSchoolList(temp)
        // temp.sort(function (a, b) {
        //   return a.distance - b.distance;
        // });
        // return temp
      }
      else {
        console.log("No userLocation")
        return orgSchoolList
      }
    }
  }


  useEffect(() => {
    const runDistance = async () => {
      let a = await getUserLocation()
      setUserLocation(a);
      orgSchoolList.current = await calDistance(schoolList, a)
      setFilteredSchool(orgSchoolList.current)
      setProgressSpinner(true)

    };
    runDistance();
    classificationDropdown()
  }, []);


  useEffect(() => {
    if (orgSchoolList.current) {
      const filteredItems = orgSchoolList.current.filter((s) => {
        return (
          (!searchSchoolName ||
            s.SchoolName.toLowerCase().includes(
              searchSchoolName.toLowerCase()
            )) &&
          (!searchPostcode ||
            s.Postcode.toLowerCase().startsWith(searchPostcode.toLowerCase()))
          && (!searchClassification || searchClassification === s.BroadClassification)
        );
      });
      setFilteredSchool(filteredItems);
    }

  }, [searchPostcode, searchSchoolName, searchClassification]);

  return (
    <>
      {/* <div className="container"> */}
      <div className="d-flex">

        {/* Search Bar */}
        <div className="sidebar sidebar-left border justify-content-between">
          <div>

            <div className="sub-topic">Filter by  <i className="pi pi-filter" style={{ fontSize: '1rem', color: "var(--grey)" }}></i>
            </div>


            <InputText value={searchSchoolName} v-model="searchSchoolName" placeholder="School Name" onChange={handleInputSchoolName} className="m-4 width90" />

            <InputText value={searchPostcode} v-model="searchPostcode" placeholder="Postcode" onChange={handleInputPostcode} className="m-4 width90" />

            {dropdownlist.length > 0 ?
              <Dropdown htmlFor="dd-classification" value={searchClassification} onChange={handleClassification} options={dropdownlist} placeholder="Select a classification" className="w-full md:w-10rem m-4 width90" />
              : <Dropdown loading placeholder="Loading..." className="w-full md:w-14rem m-4" />
            }




            <Button label="Clear" severity="secondary" outlined onClick={clearFilter} className="m-4 rounded ">
            </Button>

          </div>
          <div className="border-top m-3 ">
            <div className="p-1 text-grey">Sources from https://data.wa.gov.au/</div>
            <div className="p-1 text-grey">Website designed by Hang Tsui</div>
            <div className="p-1">
              <Link to={"https://www.linkedin.com/in/yiu-hang-tsui/"} target="_blank" className="p-2">
                <i className="pi pi-linkedin" style={{ fontSize: '1.5rem', color: "var(--grey)" }}></i>
              </Link>
              <Link to={"https://hangtsui.com"} target="_blank" className="p-2">
                <i className="pi pi-user" style={{ fontSize: '1.5rem', color: "var(--grey)" }}></i>
              </Link>
            </div>
          </div>
        </div>



        <Sidebar visible={visibleLeft} position="left" onHide={() => setVisibleLeft(false)} className="width90">
          <div className="sidebar sidebar-button">
            <div>

              <div className="sub-topic text-align-start">Filter by  <i className="pi pi-filter" style={{ fontSize: '1rem', color: "var(--grey)" }}></i>
              </div>
              <InputText value={searchSchoolName} v-model="searchSchoolName" placeholder="School Name" onChange={handleInputSchoolName} className="m-4" />

              <InputText value={searchPostcode} v-model="searchPostcode" placeholder="Postcode" onChange={handleInputPostcode} className="m-4" />

              {dropdownlist.length > 0 ?
                <Dropdown htmlFor="dd-classification" value={searchClassification} onChange={handleClassification} options={dropdownlist} placeholder="Select a classification" className="w-full md:w-10rem m-4" />
                : <Dropdown loading placeholder="Loading..." className="w-full md:w-14rem m-4 " />
              }

              <Button label="Clear" severity="secondary" outlined onClick={clearFilter} className="m-4 rounded">
              </Button>


            </div>

            <div className="m-3">
              <div className="p-1 text-grey ">Sources from https://data.wa.gov.au/</div>
              <div className="p-1 text-grey ">Website designed by Hang Tsui</div>
              <div>
                <Link to={"https://www.linkedin.com/in/yiu-hang-tsui/"} target="_blank" className="p-1">
                  <i className="pi pi-linkedin" style={{ fontSize: '1.5rem', color: "#888888" }}></i>
                </Link>
                <Link to={"https://hangtsui.com"} target="_blank" className="p-1">
                  <i className="pi pi-user" style={{ fontSize: '1.5rem', color: "#888888" }}></i>
                </Link>
              </div>
            </div>
          </div>
        </Sidebar>



        {/* Content */}
        <div className="content" >
          <div className="text-center m-1">

            <Button icon="pi pi-filter" onClick={() => setVisibleLeft(true)} severity="secondary" className="filer-icon rounded" />
            <div className="topic">WA School List <i className="pi pi-graduation-cap" style={{ fontSize: '2rem' }}> </i></div>
          </div>
          {
            filteredSchool && progressSpinner ?
              <div className="sub-topic">Total of record : {filteredSchool.length}</div>
              : <div className="sub-topic">Total of record : 0</div>
          }

          <div className="d-flex flex-row flex-wrap justify-content-evenly">

            {
              filteredSchool && progressSpinner ?
                (filteredSchool.length > 0 ?

                  filteredSchool.map((s) => (
                    <div key={s.Code} className="card flex justify-content-center col-sm-3 p-2 m-2 box">
                      <Card
                        title={s.SchoolName}
                        subTitle={s.BroadClassification}
                        className={"md:w-25rem"}
                        footer={
                          <div>
                            <Link to={`/school-details/${s.Code}`}>
                              <Button label="More details" className="mt-2 rounded" />
                            </Link>

                            {userLocation ?
                              // <span className="ms-5 badge text-bg-secondary">{haversine(userLocation, [s.Latitude, s.Longitude], { format: '[lat,lon]' }).toFixed(2)} km</span>
                              (s.distance ?
                                <span className="ms-5 badge text-bg-secondary">{s.distance.toFixed(2)} km</span>
                                : <div>NO distance</div>

                              )
                              :
                              <></>
                            }
                          </div>



                        }
                      >
                        <p className="m-1">
                          Address : {s.Street} {s.Suburb} {s.Postcode} {s.PostalState}
                        </p>


                      </Card>
                    </div>
                  ))

                  : <div>No Matching Record Found</div>)
                :
                <div className="justify-content-center">
                  <ProgressSpinner />
                </div>
            }







          </div>
        </div>


      </div >
      <ScrollButton />


    </>
  );
}

