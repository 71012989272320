import React from 'react';
import { useLocation } from 'react-router';
import { Link } from "react-router-dom";

export default function NotMatchPage() {
    const location = useLocation()
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <Link to={"/"} >
                        <i className="pi pi-home m-4" style={{ color: 'var(--primary-color)', fontSize: '2rem' }}></i>
                    </Link>
                </div>
                <div className="topic">
                    WA School List <i className="pi pi-graduation-cap" style={{ fontSize: '2rem' }}> </i></div>

            </div>
            <div className="notMatch">
                <h2>404 Not Found</h2>
                <div className="message">
                    <div>The content you trying to find does not exist.</div>
                    <div>You are at: &nbsp;<span>{location.pathname}</span></div>
                    {/* <div>{location.search}</div> */}
                </div>
            </div>
        </>
    )
}