import React from 'react';
// import './App.css';
import AppRoutes from './route';
import { BrowserRouter } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primeicons/primeicons.css';






function App({ Component }) {



  return (

    <div className="App">
      <PrimeReactProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </PrimeReactProvider>

    </div>
  );
}

export default App;
