import React, { useState } from 'react';
import { Button } from 'primereact/button';

const ScrollButton = () => {

    const [showBtn, setShowBtn] = useState("top-Btn none");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
        scrollFunction();
    };

    function scrollFunction() {
        if (
            document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20
        ) {
            setShowBtn("top-Btn");
        } else {
            setShowBtn("none");
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <Button
            onClick={topFunction}
            id="top-Btn"
            className={showBtn}
            icon="pi pi-angle-double-up"
            aria-label="top">Top</Button>
    )
}

export default ScrollButton; 